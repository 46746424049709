@tailwind base;
@tailwind components;
@tailwind utilities;

@import './tailwind-variables';

* {
  outline-color: theme('colors.primary') !important;
}

html,
body {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  color: theme('colors.gray.900');
  background: theme('colors.gray.100');
  font-size: 16px; // for easy PX to REM conversion. We apply .body-1 as default
}

html,
body,
#root,
#__next {
  height: 100%;
  // height: 100% !important;
}

/* CUSTOMIZATION */

// .carousel.carousel-slider {
//   overflow: initial;
// }

// .carousel .control-dots {
//   margin-bottom: -30px;
// }

.swiper-wrapper {
  align-items: stretch;

  .swiper-slide {
    height: auto !important;
  }
}

/*   
  Phone Number Input classess
*/

.react-tel-input .form-control {
  width: 100% !important;
  height: auto !important;
  border-radius: 0.375rem;
  border: solid 1px #9e9e9e !important;
  order: 1;
  padding: 0.5rem 0.75rem !important;
  padding-left: 60px !important;
}

.react-tel-input .form-control .phone-lg {
  padding: 1rem 1.25rem !important;
  background-color: red !important;
}

.input-range__slider {
  background: theme('colors.primary') !important;
  border-color: theme('colors.primary') !important;
}

.input-range__track--active {
  background: theme('colors.primary') !important;
}

.input-range--disabled .input-range__slider {
  background: #cccccc !important;
  border: 1px solid #cccccc !important;
  box-shadow: none;
  transform: none;
}
.input-range--disabled .input-range__track {
  background: #eeeeee !important;
}

.react-player > div > iframe {
  margin: 0px !important;
  width: 100% !important;
}

.chat-gradient {
  // -webkit-box-shadow: 0px -19px 160px 18px rgba(0, 89, 255, 1);
  // -moz-box-shadow: 0px -19px 160px 18px rgba(0, 89, 255, 1);
  // box-shadow: 0px -19px 160px 18px rgba(0, 89, 255, 1);
  box-shadow: 0px -15px 20px -3px white, 0px -15px 20px -3px white;
}

.chrome-picker {
  width: 100% !important;
  box-shadow: none !important;
  background-color: transparent !important;
  max-width: 100%;
  position: relative;
}

.swiper-pagination-bullet {
  background-color: #e0e0e0 !important;
  opacity: 1 !important;
}

.swiper-pagination-bullet-active {
  background-color: theme('colors.primary') !important;
}

.swiper-button-next {
  color: theme('colors.primary') !important;
}
.swiper-button-prev {
  color: theme('colors.primary') !important;
}

@function hexToRGB($hex) {
  @return red($hex), green($hex), blue($hex);
}

@each $name, $color in $themes {
  @if $name == 'default' {
    :root {
      --color-primary: #{hexToRGB(map-get($color, 'primary'))};
      --color-secondary: #{hexToRGB(map-get($color, 'secondary'))};
    }
  }

  .theme-#{$name} {
    --color-primary: #{hexToRGB(map-get($color, 'primary'))};
    --color-secondary: #{hexToRGB(map-get($color, 'secondary'))};
  }
}

pre code {
  font-family: monospace;
  background-color: white;
  border: 1px solid #999;
  display: block;
  padding: 10px;
  font-size: 0.8em;
  min-width: 100%;
  width: 0px;
  overflow: auto;
}

$transition-time: 150ms;

//React Slider
.input-range__slider {
  width: 1.375rem !important;
  height: 1.375rem !important;
  margin-top: -0.85rem !important;
}

.input-range__label--value {
  top: -2.2rem !important;
}

// TODO: Does not support React 18

.min-w-0 {
  min-width: 0;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 200ms;
}

@keyframes pingSmall {
  75%,
  100% {
    transform: scale(1.1, 1.7);
    opacity: 0;
  }
}

.animate-ping-small {
  animation: pingSmall 1s cubic-bezier(0, 0, 0.2, 0.7) infinite;
}

// nprogress
#nprogress {
  .bar {
    height: 4px;
    background-color: theme('colors.primary');
  }

  .spinner {
    display: none !important;
    top: 9px;
    right: max(12px, (calc((100vw - 1540px) / 2) + 7px));
  }

  .spinner-icon {
    width: 38px;
    height: 38px;
    border-top-color: theme('colors.primary');
    border-left-color: theme('colors.primary');
  }
}

.instagram-gradient {
  background: #d6249f;
  background: -moz-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );

  &:hover {
    background: -moz-linear-gradient(
      225deg,
      #f09433 0%,
      #e6683c 25%,
      #dc2743 50%,
      #cc2366 75%,
      #bc1888 100%
    );
    background: -webkit-linear-gradient(
      225deg,
      #f09433 0%,
      #e6683c 25%,
      #dc2743 50%,
      #cc2366 75%,
      #bc1888 100%
    );
    background: linear-gradient(
      225deg,
      #f09433 0%,
      #e6683c 25%,
      #dc2743 50%,
      #cc2366 75%,
      #bc1888 100%
    );
  }
}

.boujee-pro {
  --bg-size: 400%;
  --color-one: #ffa102;
  --color-two: #e6683c;

  background: linear-gradient(
      90deg,
      var(--color-one),
      var(--color-two),
      var(--color-one)
    )
    0 0 / var(--bg-size) 100%;
}

.boujee-pro-text {
  color: transparent;
  background-clip: text;
}

@media (prefers-reduced-motion: no-preference) {
  .boujee-pro,
  .boujee-pro-text {
    animation: move-bg 8s linear infinite;
  }
  @keyframes move-bg {
    to {
      background-position: var(--bg-size) 0;
    }
  }
}

.animated-banner-border {
  border-width: 2px;
  animation: animatedBannerborder 700ms infinite;
}

@keyframes animatedBannerborder {
  0% {
    border-style: dashed;
  }
  50% {
    border-style: dotted;
  }
  100% {
    border-style: dashed;
  }
}
