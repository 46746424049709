.root {
  background-size: cover;
  background-position: center;
}

@mixin onEnter() {
  opacity: 0.5;
}

@mixin onExit() {
  opacity: 1;
}

.enter {
  @include onEnter();
}

.exit {
  @include onExit();
}

.enterActive {
  @include onExit();
}

.exitActive {
  @include onEnter();
}

.enterActive,
.exitActive {
  transition: all 200ms;
}
